// This is the ticket component that appears in my trips, shared-ticket, and so on.
// It represents a single ticket view.

import React, { useRef } from 'react';
import QRCode from 'qrcode.react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ReactToPrint from 'react-to-print';
import domtoimage from 'dom-to-image';
import { Button } from 'components';
import { colors, copyToClipboard, notify, useFormattedTime } from 'utilities';
import TripDepartureBackground from './images/departure-icon.svg';
import TripArrivalBackground from './images/arrival-icon.svg';
import TripDateBackgound from './images/date-icon.svg';
import { isMobile } from 'react-device-detect';

const TicketContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  margin-bottom: 40px;

  @media only screen and (max-width: 980px) {
    flex-direction: column;
  }

  @media print {
    @page {
      margin-left: 20%;
    }
  }
`;

const TripInfo = styled.div`
  background: #002d59;
  color: #fff;
  border-radius: 10px;
  padding: 34px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 33%;
  flex-grow: 1;

  p {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
  span {
    margin-inline-start: 30px;
  }
`;

const TripDetails = styled.div`
  flex-basis: 33%;
  padding: 34px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 10px;
  flex-grow: 1;
`;

const TripParameter = styled.p`
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`;

const TripTotal = styled.p`
  margin: 0;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: space-between;

  span {
    font-weight: 700;
  }
  @media screen and (max-width: 1025px) {
    margin-top: 15px;
  }
`;

const TripActionsList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-inline-start: 10px;
  list-style: none;
  justify-content: space-between;
  a {
    font-size: 14px;
    font-weight: 500;
  }

  @media print {
    display: none;
  }
`;

const NumberWrapper = styled.p`
  margin-top: 20px;
  color: ${colors.primaryColor};
`;

const TripShare = styled.div`
  background: #fff;
  padding: 34px 20px 20px 20px;
  display: flex;
  flex-basis: 33%;
  justify-content: space-between;
  border-inline-start: 1px dashed #002d59;
  position: relative;
  border-radius: 10px;
  flex-direction: column;
  flex-grow: 1;

  @media only screen and (max-width: 980px) {
    border-inline-start: none;
    border-top: 1px dashed #002d59;
    padding: 34px 20px 0 20px;
  }

  &::before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    top: -15px;
    ${props => props.start}: -15px;
    border-radius: 50%;
    background: #f6f7f9;
    box-shadow: 0 10px #fff;
  }

  &::after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -15px;
    ${props => props.start}: -15px;
    border-radius: 50%;
    background: #f6f7f9;
    box-shadow: 0 -10px #fff;
  }

  div:first-of-type {
    display: flex;
    justify-content: space-between;
  }
`;

const TripDepature = styled.p`
  padding-inline-start: 30px;
  background: url(${TripDepartureBackground})
    ${props => props.backgroundPosition} 50% no-repeat;
`;

const TripArrival = styled.p`
  padding-inline-start: 30px;
  background: url(${TripArrivalBackground}) ${props => props.backgroundPosition}
    50% no-repeat;
  @media screen and (max-width: 1025px) {
    margin-top: 15px !important;
  }
`;

const TicketStatus = styled.span`
  display: block;
  color: ${props => props.color};
  margin-bottom: 0;
  font-size: 14px;

  ::before {
    content: '';
    display: inline-block;
    border: 1px solid ${props => props.color};
    width: 8px;
    height: 8px;
    border-radius: 50px;
    margin-inline-end: 5px;
  }
`;

const TripDate = styled.p`
  padding-inline-start: 30px;
  background: url(${TripDateBackgound}) ${props => props.backgroundPosition} 50%
    no-repeat;
  @media screen and (max-width: 1025px) {
    margin-top: 15px !important;
  }
`;
const TripShareContainer = styled.div`
  flex-direction: ${isMobile ? 'column' : 'none'};
`

const Ticket = ({
  seat: {
    ticketId,
    ticketUUID,
    seatNumber,
    seatTypeNameEn,
    seatTypeName,
    ticketCode,
    price,
    qrCode,
    ticketStatus,
    ticketStatusAr,
    orderNumber,
    from_time,
    to_time,
    tripCode,
  },
  ticketDetails: {
    fromCityName,
    fromCityNameAr,
    fromLocationName,
    fromLocationNameAr,
    toCityName,
    toCityNameAr,
    toLocationName,
    toLocationNameAr,
    date,
    fromDate,
    tripId,
    fromLocationTime,
    toLocationTime,
  },
  isSummary
}) => {
  const { t } = useTranslation('ticket');
  const { language } = i18next;

  const componentRef = useRef();
  const saveTicket = () => {
    const el = document.getElementById(ticketId);
    document.querySelector('.action-list').style.visibility = 'hidden';
    domtoimage.toBlob(el).then(function (blob) {
      window.saveAs(blob, `Ticket_${ticketId}.png`);
      document.querySelector('.action-list').style.visibility = 'unset';
    });
  };
  const shareTicket = () => {
    copyToClipboard(`${process.env.REACT_APP_WEBSITE_URL}/ticket/${ticketUUID}`);
    notify('success', t('validations.shareValidation'));
  };

  const { time: fromTime } = useFormattedTime(isSummary ?fromLocationTime : from_time);
  const { time: toTime } = useFormattedTime(isSummary? toLocationTime: to_time);

  const start = language === 'en' ? 'left' : 'right';
  const backgroundPosition = language === 'en' ? '0%' : '100%';
  const color =
    ticketStatus === 'Cancelled'
      ? colors.danger
      : ticketStatus === 'Paid'
        ? colors.success
        : colors.primaryColor;

  return (
    <TicketContainer id={ticketId} ref={componentRef}>
      <TripInfo>
        <TripDepature backgroundPosition={backgroundPosition}>
          {language === 'en' ? fromCityName : fromCityNameAr} -{' '}
          {language === 'en' ? fromLocationName : fromLocationNameAr}{' '}
          <span>{fromTime}</span>
        </TripDepature>
        <TripArrival backgroundPosition={backgroundPosition}>
          {language === 'en' ? toCityName : toCityNameAr} -{' '}
          {language === 'en' ? toLocationName : toLocationNameAr}{' '}
          <span>{toTime}</span>
        </TripArrival>
        <TripDate backgroundPosition={backgroundPosition}>
          {t('fields.departureDate')} <span>{fromDate}</span>
        </TripDate>
      </TripInfo>
      <TripDetails>
        <TripParameter>
          {t('fields.tripNumber')} <span>{tripCode}</span>
        </TripParameter>
        <TripParameter>
          {t('fields.seatNumber')} {seatNumber}{' '}
          <span>{language === 'en' ? seatTypeNameEn : seatTypeName}</span>
        </TripParameter>
        <TripTotal>
          <span>
            {t('fields.price')}{' '}
            <TicketStatus color={color} style={{ marginTop: '10px' }}>
              {language === 'en' ? ticketStatus : ticketStatusAr}
            </TicketStatus>
          </span>
          <span>
            {price} {t('common:money.pound', { count: price })}
          </span>
        </TripTotal>
      </TripDetails>

      <TripShare start={start}>
        <TripShareContainer>
          <div>
            {qrCode &&( <QRCode value={qrCode} />)}
          </div>
          <TripActionsList className="action-list">
            <li>
              <Button
                small={isMobile ? true : false}
                clean
                onClick={() => {
                  shareTicket();
                }}
              >
                {t('buttons.shareButton')}
              </Button>
            </li>
            <li>
              <ReactToPrint
                trigger={() => (
                  <Button small={isMobile ? true : false} clean>
                    {' '}
                    {t('buttons.printButton')}
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </li>
            <li>
              <Button
                small={isMobile ? true : false}
                clean
                onClick={() => {
                  saveTicket();
                }}
              >
                {t('buttons.saveButton')}
              </Button>
            </li>
          </TripActionsList>
        </TripShareContainer>

        <NumberWrapper>
          {t('fields.orderNumber')}: {orderNumber}
        </NumberWrapper>
        <NumberWrapper>
          {t('fields.ticketCode')}: {ticketCode}
        </NumberWrapper>
      </TripShare>
    </TicketContainer>
  );
};

export default Ticket;
